<template>
  <div v-for="(step, stepKey) in currentAccordionSteps" :key="stepKey">
    <div v-if="stepKey === 'model'" class="mb-2">
      <p class="text-sm font-semibold text-gray-900 mb-2">{{ step.title }}</p>

      <div v-if="step.options" class="flex flex-wrap gap-2">
        <label
          v-for="(option, index) in step.options"
          :key="`${stepKey}-${index}`"
          :class="[
            'flex flex-col items-center  px-2 py-1 justify-center shadow w-fit h-full border border-gray-300 rounded cursor-pointer',
            step.selectedOption.value === option.value
              ? 'bg-blue-600 text-white'
              : schmitzStore.vehicleSelectionError
                ? 'text-audaris-red'
                : 'bg-white hover:bg-gray-200 text-gray-800'
          ]"
          @click.self="schmitzStore.selectOptions(option, stepKey)"
        >
          <input
            type="radio"
            :id="`${stepKey}-${index}`"
            :value="option"
            v-model="step.selectedOption"
            class="hidden"
          />
          {{ option.label }}
        </label>
      </div>
    </div>
    <div v-if="stepKey === 'modelVariant' && showModelVariant" class="mb-2">
      <p class="text-sm font-semibold text-gray-900 mb-2">{{ step.title }}</p>
      <div v-if="step.options" class="flex flex-wrap gap-2">
        <label
          v-for="(option, index) in step.options"
          :key="`${stepKey}-${index}`"
          :class="[
            'flex flex-col items-center  px-2 py-1 justify-center shadow w-fit h-full border border-gray-300 rounded cursor-pointer',
            step.selectedOption.value === option.value
              ? 'bg-blue-600 text-white'
              : schmitzStore.vehicleSelectionError
                ? 'text-audaris-red'
                : 'bg-white hover:bg-gray-200'
          ]"
          @click.self="schmitzStore.selectOptions(option, stepKey)"
        >
          <input
            type="radio"
            :id="`${stepKey}-${index}`"
            :value="option"
            v-model="step.selectedOption"
            class="hidden"
          />
          {{ option.label }}
        </label>
      </div>
    </div>
    <div v-if="stepKey === 'driveType' && showDriveType" class="mb-2">
      <p class="text-sm font-semibold text-gray-900 mb-2">{{ step.title }}</p>
      <div v-if="step.options" class="flex flex-wrap gap-2">
        <label
          v-for="(option, index) in step.options"
          :key="`${stepKey}-${index}`"
          :class="[
            'flex flex-col items-center px-2 py-1 justify-center shadow w-fit h-full border border-gray-300 rounded cursor-pointer',
            step.selectedOption.value === option.value
              ? 'bg-blue-600 text-white'
              : schmitzStore.vehicleSelectionError
                ? 'text-audaris-red'
                : 'bg-white hover:bg-gray-200'
          ]"
          @click.self="schmitzStore.selectOptions(option, stepKey)"
        >
          <input
            type="radio"
            :id="`${stepKey}-${index}`"
            :value="option"
            v-model="step.selectedOption"
            class="hidden"
          />
          {{ option.label }}
        </label>
      </div>
    </div>
  </div>
  <div>
    <p
      v-if="schmitzStore.vehicleSelectionError"
      class="mt-4 text-xs text-red-500 text-right whitespace-pre-line"
    >
      {{ schmitzStore.vehicleSelectionError }}
    </p>
    <button
      class="mt-4 ml-auto flex items-center bg-blue-600 text-white font-semibold text-xl px-4 py-1 border rounded shadow-md hover:bg-blue-800 disabled:opacity-50"
      @click="schmitzStore.nextSelection()"
    >
      Weiter
    </button>
  </div>
</template>

<script setup>
import { useSchmitzStore } from '@/stores/schmitzStore'
import { computed, ref } from 'vue'

const schmitzStore = useSchmitzStore()

const date = ref(new Date())
const currentAccordionSteps = computed(() => {
  return schmitzStore.accordion[schmitzStore.currentExpandedKey].steps
})

const showModelVariant = computed(() => {
  return schmitzStore.accordion[schmitzStore.currentExpandedKey].steps.model.selected
})

const showDriveType = computed(() => {
  const modelVariant =
    schmitzStore.accordion[schmitzStore.currentExpandedKey].steps.modelVariant.selectedOption
  return (
    modelVariant &&
    (modelVariant.value === 'ModelYLongRange' || modelVariant.value === 'Model3LongRange')
  )
})
</script>
