<template>
  <div v-if="currentStepPath" class="p-2">
    <div class="flex flex-wrap -mx-2">
      <label class="block w-full md:w-1/2 mt-4 px-2"
        ><span class="text-sm font-semibold">Vorname *</span
        ><input
          type="text"
          class="form-input w-full mt-1"
          v-model="selectedOptionPath.firstName" /></label
      ><label class="block w-full md:w-1/2 mt-4 px-2"
        ><span class="text-sm font-semibold">Nachname *</span
        ><input type="text" class="form-input w-full mt-1" v-model="selectedOptionPath.lastName"
      /></label>
    </div>
    <div class="flex flex-wrap -mx-2">
      <label class="block w-full md:w-1/2 mt-4 px-2"
        ><span class="text-sm font-semibold">E-Mail *</span
        ><input type="email" class="form-input w-full mt-1" v-model="selectedOptionPath.email" />
        <div v-if="!validateEmail(selectedOptionPath.email)" class="text-red-500 text-xs">
          Bitte geben Sie eine gültige E-Mail-Adresse ein.
        </div>
      </label>

      <label class="block w-full md:w-1/2 mt-4 px-2">
        <span class="text-sm font-semibold">Telefon *</span>
        <input type="phone" class="form-input w-full mt-1" v-model="selectedOptionPath.phone" />
      </label>
    </div>
    <label class="block mt-4">
      <span class="text-sm font-semibold">Nachricht</span>
      <textarea class="form-input w-full mt-1" v-model="selectedOptionPath.message"></textarea>
    </label>
    <h3 class="mt-4 mr-2 text-sm font-semibold text-gray-900">
      Wie haben Sie von uns erfahren? (Mehrfachauswahl möglich) *
    </h3>
    <div class="flex flex-wrap gap-2 mt-4">
      <label
        v-for="(option, index) in currentStepPath.options"
        :key="`${currentStepPath}-${index}`"
        class="flex flex-col items-center px-2 py-1 justify-center shadow w-fit h-full border border-gray-300 rounded cursor-pointer"
        :class="{
          'bg-blue-600 text-white': selectedOptionPath.marketing.includes(option),
          'bg-white': !selectedOptionPath.marketing.includes(option)
        }"
      >
        <input
          type="checkbox"
          :id="`equipment-${index}`"
          :value="option"
          @change="schmitzStore.selectMutlipleCheckboxes(option, schmitzStore.currentStepKey)"
          class="hidden"
        />
        {{ option.label }}
      </label>
    </div>
    <div>
      <label class="block mt-4">
        <input
          id="privacyPolicy"
          type="checkbox"
          class="form-checkbox mr-2"
          v-model="selectedOptionPath.privacyPolicy"
        />Ich habe die
        <a :href="`/datenschutz`" target="_blank" class="text-blue-600 underline font-semibold"
          >Datenschutzerklärung</a
        >
        und die
        <a :href="`/blog/7674`" target="_blank" class="text-blue-600 underline font-semibold"
          >AGB</a
        >
        gelesen und verstanden. *</label
      >
      <p class="text-xs my-4">* - Pflichtfelder</p>
    </div>
  </div>
  <div class="flex justify-end py-4">
    <button
      @click="schmitzStore.goBack()"
      class="flex items-center px-2 py-1 text-gray-600 text-xs font-light hover:underline"
    >
      <svg class="arrow-icons mx-2">
        <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
      </svg>
      Zurück
    </button>
    <button
      class="ml-4 flex items-center border rounded shadow px-2 py-1"
      :class="[
        isButtonDisabled
          ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
          : 'bg-blue-600 text-white font-semibold shadow-md hover:bg-blue-800'
      ]"
      @click="schmitzStore.sendRequest()"
      :disabled="isButtonDisabled"
    >
      Anfrage senden
    </button>
  </div>
</template>

<script setup>
import { computed, watch, onMounted } from 'vue'
import { useSchmitzStore } from '@/stores/schmitzStore'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const schmitzStore = useSchmitzStore()

const currentStepPath = computed(() => {
  return schmitzStore.accordion[schmitzStore.currentExpandedKey].steps[schmitzStore.currentStepKey]
})
const selectedOptionPath = computed(() => {
  return schmitzStore.accordion[schmitzStore.currentExpandedKey].steps[schmitzStore.currentStepKey]
    .selectedOption
})
function validateEmail(email) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return emailRegex.test(email)
}

const isButtonDisabled = computed(() => {
  return (
    !selectedOptionPath.value ||
    !selectedOptionPath.value.privacyPolicy ||
    !selectedOptionPath.value.firstName ||
    !selectedOptionPath.value.lastName ||
    !validateEmail(selectedOptionPath.value.email) ||
    !selectedOptionPath.value.phone
  )
})
</script>
