<template>
  <div v-if="schmitzStore.currentStepKey === 'price'">
    <div class="my-2 flex justify-between items-center text-gray-600 text-xs">
      <h3 class="mr-2 text-sm font-semibold text-gray-900">{{ currentStepPath.title }}</h3>
      <button
        @click="schmitzStore.goBack()"
        class="flex items-center px-2 py-1 text-gray-600 text-xs font-light hover:underline"
      >
        <svg class="arrow-icons mx-2">
          <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
        </svg>
        Zurück
      </button>
    </div>
    <div class="flex items-start lg:flex-row sm:flex-col">
      <label class="block w-full md:w-auto mt-4 px-2"
        ><span class="text-sm font-semibold">Verkauf von</span
        ><select class="form-select max-w-full mt-1" v-model="currentStepPathOption.saleType">
          <option value="Privater Verkauf">Privater Verkauf</option>
          <option value="Gewerblicher Verkauf">Gewerblicher Verkauf</option>
        </select></label
      >
      <label
        v-show="currentStepPathOption.saleType === 'Gewerblicher Verkauf'"
        class="block w-full md:w-auto mt-4 px-2"
        ><span class="text-sm font-semibold">Mehrwertsteuer</span
        ><select class="form-select max-w-full mt-1" v-model="currentStepPathOption.mwSt">
          <option value="true">MwSt. ausweisbar</option>
          <option value="false">MwSt. nicht ausweisbar</option>
        </select></label
      >
    </div>
    <label class="block w-full md:w-1/2 mt-4 px-2"
      ><span class="text-sm font-semibold">Mindestpreis (Euro) *</span
      ><input
        type="number"
        class="form-input w-full mt-1"
        v-model="currentStepPathOption.priceRequest"
    /></label>
  </div>

  <div v-else-if="currentStepPathOption" class="flex flex-col my-2 gap-2">
    <div class="my-2 flex justify-between items-center text-gray-600 text-xs">
      <h3 class="mr-2 text-sm font-semibold text-gray-900">{{ currentStepPath.title }}</h3>
      <button
        @click="schmitzStore.goBack()"
        class="flex items-center px-2 py-1 text-gray-600 text-xs font-light hover:underline"
      >
        <svg class="arrow-icons mx-2">
          <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
        </svg>
        Zurück
      </button>
    </div>
    <div class="flex flex-col gap-2">
      <div class="flex flex-row gap-2">
        <label
          for="notFinanced"
          :class="{
            'bg-white hover:bg-gray-200': currentStepPathOption.financing,
            'bg-blue-600 text-white': !currentStepPathOption.financing
          }"
          class="flex flex-col items-center px-2 py-1 justify-center shadow w-fit h-full border border-gray-300 rounded cursor-pointer"
        >
          <input
            type="radio"
            id="notFinanced"
            v-model="currentStepPathOption.financing"
            :value="false"
            class="hidden"
          />
          Keine Finanzierung
        </label>
        <label
          for="financed"
          :class="{
            'bg-white hover:bg-gray-200': !currentStepPathOption.financing,
            'bg-blue-600 text-white': currentStepPathOption.financing
          }"
          class="flex flex-col items-center px-2 py-1 justify-center shadow w-fit h-full border border-gray-300 rounded cursor-pointer"
        >
          <input
            type="radio"
            id="financed"
            v-model="currentStepPathOption.financing"
            :value="true"
            class="hidden"
          />
          Fahrzeug wurde Finanziert
        </label>
      </div>

      <div v-if="currentStepPathOption.financing" class="flex flex-wrap gap-x-2 gap-y-2">
        <div class="flex flex-col">
          <h3 class="mt-4 my-2 mx-2 text-sm font-semibold text-gray-900">
            Liegt der KFZ-Brief (Zulassungsbescheinigung Teil 2) bei der Bank?
          </h3>
          <div class="flex flex-wrap my-2 gap-2">
            <label
              for="onMe"
              :class="{
                'bg-white hover:bg-gray-200': currentStepPathOption.vehicleLetterAtBank,
                'bg-blue-600 text-white': !currentStepPathOption.vehicleLetterAtBank
              }"
              class="flex flex-col items-center px-2 py-1 justify-center shadow w-fit h-fit border border-gray-300 rounded cursor-pointer"
            >
              <input
                type="radio"
                id="onMe"
                v-model="currentStepPathOption.vehicleLetterAtBank"
                :value="false"
                class="hidden"
              />
              KFZ-Brief liegt bei mir
            </label>
            <label
              for="bank"
              :class="{
                'bg-white hover:bg-gray-200': !currentStepPathOption.vehicleLetterAtBank,
                'bg-blue-600 text-white': currentStepPathOption.vehicleLetterAtBank
              }"
              class="flex flex-col items-center px-2 py-1 justify-center shadow w-fit h-fit border border-gray-300 rounded cursor-pointer"
            >
              <input
                type="radio"
                id="bank"
                v-model="currentStepPathOption.vehicleLetterAtBank"
                :value="true"
                class="hidden"
              />
              KFZ-Brief liegt bei der Bank
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex justify-end py-4">
    <button
      @click="schmitzStore.nextSelection()"
      :disabled="schmitzStore.currentStepKey === 'price' && !currentStepPathOption.priceRequest"
      :class="[
        schmitzStore.currentStepKey === 'price' && !currentStepPathOption.priceRequest
          ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
          : 'bg-blue-600 text-white font-semibold shadow-md hover:bg-blue-800'
      ]"
      class="ml-4 flex items-center border rounded shadow px-2 py-1"
    >
      Weiter
    </button>
  </div>
</template>

<script setup>
import { computed, watch } from 'vue'
import { useSchmitzStore } from '@/stores/schmitzStore'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const schmitzStore = useSchmitzStore()
let currentStepPath = computed(() => {
  return schmitzStore.accordion[schmitzStore.currentExpandedKey].steps[schmitzStore.currentStepKey]
})
let currentStepPathOption = computed(() => {
  return schmitzStore.accordion[schmitzStore.currentExpandedKey].steps[schmitzStore.currentStepKey]
    .selectedOption
})
watch(
  currentStepPathOption,
  (newValue, oldValue) => {
    if (newValue.saleType === 'PRIVATE') {
      newValue.mwSt = false
    }
    if (newValue.financing === false) {
      newValue.vehicleLetterAtBank = false
    }
  },
  { deep: true }
)
</script>
