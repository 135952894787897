<template>
  <div>
    <div class="my-2 flex justify-between items-center text-gray-600 text-xs">
      <h3 class="mr-2 text-sm font-semibold text-gray-900">{{ currentStepPath.title }}</h3>
      <!-- <button class="ml-auto mr-2 flex items-center hover:underline">Zurück</button> -->
      <button
        @click="schmitzStore.goBack()"
        class="flex items-center px-2 py-1 text-gray-600 text-xs font-light hover:underline"
      >
        <svg class="arrow-icons mx-2">
          <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
        </svg>
        Zurück
      </button>
    </div>
    <div v-if="currentStepPath" class="relative flex flex-wrap my-2 gap-2">
      <template v-if="schmitzStore.currentStepKey !== 'rimColor'">
        <label
          v-for="(option, index) in currentStepPath.options"
          :key="`${currentStepPath}-${index}`"
          :title="option.label"
          :class="[
            'flex',
            'flex-col',
            'w-16',
            'h-16',
            'rounded-md',
            'hover:ring-4',
            getColorClass(option.value),
            currentStepPath.selectedOption && currentStepPath.selectedOption.value === option.value
              ? 'ring-4 ring-blue-600'
              : ''
          ]"
          @click.self="schmitzStore.selectOptions(option, schmitzStore.currentStepKey)"
        >
          <input
            type="radio"
            :id="`${currentStepPath}-${index}`"
            :value="option"
            v-model="currentStepPath.selectedOption"
            class="hidden"
          />
        </label>
      </template>
      <template v-else>
        <div class="mb-2">
          <div v-if="currentStepPath.options" class="flex flex-wrap gap-2">
            <label
              v-for="(option, index) in currentStepPath.options"
              :key="`${currentStepPath}-${index}`"
              :class="[
                'flex flex-col items-center  px-2 py-1 justify-center shadow w-fit h-full border border-gray-300 rounded cursor-pointer',
                currentStepPath.selectedOption.value === option.value
                  ? 'bg-blue-600 text-white'
                  : schmitzStore.vehicleSelectionError
                    ? 'text-audaris-red'
                    : 'bg-white hover:bg-gray-200 text-gray-800'
              ]"
              @click.self="schmitzStore.selectOptions(option, schmitzStore.currentStepKey)"
            >
              <input
                type="radio"
                :id="`${currentStepPath}-${index}`"
                :value="option"
                v-model="currentStepPath.selectedOption"
                class="hidden"
              />
              {{ option.label }}
            </label>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { useSchmitzStore } from '@/stores/schmitzStore'
import { computed } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const schmitzStore = useSchmitzStore()

const currentStepPath = computed(() => {
  return schmitzStore.accordion[schmitzStore.currentExpandedKey].steps[schmitzStore.currentStepKey]
})

function getColorClass(color) {
  switch (color) {
    case 'silver':
      return 'bg-gray-400'
    case 'black':
      return 'bg-gray-900'
    case 'red':
      return 'bg-red-600'
    case 'blue':
      return 'bg-blue-600'
    case 'gray':
      return 'bg-zinc-500'
    default:
      return 'bg-gray-100' // or any other default color you want
  }
}
</script>
