<template>
  <div class="flex flex-col items-center text-center p-8">
    <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" style="color: red; height: 250px" />
    <p class="text-xl mt-8">
      Leider ist beim Senden Ihrer Anfrage ein Fehler aufgetreten. Sie können es gerne hier noch
      einmal probieren:
    </p>
    <p>{{ schmitzStore.requestError }}</p>
    <button
      @click="schmitzStore.requestError = null"
      class="mt-4 flex items-center bg-blue-600 text-white font-semibold text-xl px-4 py-1 border rounded shadow-md hover:bg-blue-800"
    >
      Zurück zum Ankaufformular
    </button>
  </div>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { useSchmitzStore } from '@/stores/schmitzStore'

const schmitzStore = useSchmitzStore()
</script>
