<template>
  <div class="flex flex-col items-center text-center p-8">
    <FontAwesomeIcon icon="fa-regular fa-circle-check" style="height: 240px; color: #36ff04" />
    <p class="text-xl mt-8">
      Die Anfrage wurde erfolgreich an uns gesendet. Wir werden uns in wenigen Minuten mit unserem
      Angebot bei Ihnen melden.<br />
    </p>
  </div>
</template>

<script setup>
import { useSchmitzStore } from '@/stores/schmitzStore'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
const schmitzStore = useSchmitzStore()
</script>
