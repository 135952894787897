<template>
  <div>
    <div v-if="currentStepPath && schmitzStore.currentStepKey === 'autopilot'">
      <div class="my-2 flex justify-between items-center text-gray-600 text-xs">
        <p class="text-sm font-semibold text-gray-900 mb-2">{{ currentStepPath.title }}</p>
        <button
          @click="schmitzStore.goBack()"
          class="flex items-center px-2 py-1 text-gray-600 text-xs font-light hover:underline"
        >
          <svg class="arrow-icons mx-2">
            <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
          </svg>
          Zurück
        </button>
      </div>
      <div class="flex flex-wrap gap-2">
        <label
          v-for="(option, index) in currentStepPath.options"
          :key="`${currentStepPath}-${index}`"
          :class="[
            'flex flex-col items-center px-2 py-1 justify-center shadow w-fit h-full border border-gray-300 rounded cursor-pointer',
            currentStepPath.selectedOption.value === option.value
              ? 'bg-blue-600 text-white'
              : schmitzStore.vehicleSelectionError
                ? 'text-audaris-red'
                : 'bg-white hover:bg-gray-200'
          ]"
          @click.self="schmitzStore.selectOptions(option, schmitzStore.currentStepKey)"
        >
          <input
            type="radio"
            :id="`${currentStepPath}-${index}`"
            :value="option"
            v-model="currentStepPath.selectedOption"
            class="hidden"
          />
          {{ option.label }}
        </label>
      </div>
    </div>

    <div
      v-if="
        (currentStepPath && schmitzStore.currentStepKey === 'rims') ||
        schmitzStore.currentStepKey === 'rimType'
      "
      class="mb-2"
    >
      <div class="my-2 flex justify-between items-center text-gray-600 text-xs">
        <p class="text-sm font-semibold text-gray-900">
          {{ schmitzStore.accordion.accordion4.steps.rims.title }}
        </p>
        <button
          @click="schmitzStore.goBack()"
          class="flex items-center px-2 py-1 text-gray-600 text-xs font-light hover:underline"
        >
          <svg class="arrow-icons mx-2">
            <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
          </svg>
          Zurück
        </button>
      </div>
      <div class="flex flex-wrap gap-2 mt-4">
        <label
          v-for="(option, index) in schmitzStore.accordion.accordion4.steps.rims.options"
          :key="`${schmitzStore.accordion.accordion4.steps.rims}-${index}`"
          :class="[
            'flex flex-col items-center px-2 py-1 justify-center shadow w-fit h-full border border-gray-300 rounded cursor-pointer',
            schmitzStore.accordion.accordion4.steps.rims.selectedOption.value === option.value
              ? 'bg-blue-600 text-white'
              : schmitzStore.vehicleSelectionError
                ? 'text-audaris-red'
                : 'bg-white hover:bg-gray-200'
          ]"
          @click.self="schmitzStore.selectOptions(option, 'rims')"
        >
          <input
            type="radio"
            :id="`${schmitzStore.accordion.accordion4.steps.rims}-${index}`"
            :value="option"
            v-model="schmitzStore.accordion.accordion4.steps.rims.selectedOption"
            class="hidden"
          />
          {{ option.label }}
        </label>
      </div>

      <!-- RimTypes options -->
      <p class="text-sm font-semibold text-gray-900 mb">
        {{ schmitzStore.accordion.accordion4.steps.rimType.title }}
      </p>
      <div class="flex flex-wrap gap-2 mt-4">
        <label
          v-for="(option, index) in schmitzStore.accordion.accordion4.steps.rimType.options"
          :key="`${schmitzStore.accordion.accordion4.steps.rimType}-${index}`"
          :class="[
            'flex flex-col items-center px-2 py-1 justify-center shadow w-fit h-full border border-gray-300 rounded cursor-pointer',
            schmitzStore.accordion.accordion4.steps.rimType.selectedOption.value === option.value
              ? 'bg-blue-600 text-white'
              : schmitzStore.vehicleSelectionError
                ? 'text-audaris-red'
                : 'bg-white hover:bg-gray-200'
          ]"
          @click.self="schmitzStore.selectOptions(option, 'rimType')"
        >
          <input
            type="radio"
            :id="`${schmitzStore.accordion.accordion4.steps.rimType}-${index}`"
            :value="option"
            v-model="schmitzStore.accordion.accordion4.steps.rimType.selectedOption"
            class="hidden"
          />
          {{ option.label }}
        </label>
      </div>
    </div>

    <div v-if="currentStepPath && schmitzStore.currentStepKey === 'customEquipment'">
      <div class="my-2 flex justify-between items-center text-gray-600 text-xs">
        <p class="text-sm font-semibold text-gray-900 mb-2">{{ currentStepPath.title }}</p>
        <button
          @click="schmitzStore.goBack()"
          class="flex items-center px-2 py-1 text-gray-600 text-xs font-light hover:underline"
        >
          <svg class="arrow-icons mx-2">
            <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
          </svg>
          Zurück
        </button>
      </div>
      <div class="flex flex-wrap gap-2 mt-4">
        <label
          v-for="(option, index) in currentStepPath.options"
          :key="`${currentStepPath}-${index}`"
          class="flex flex-col items-center px-2 py-1 justify-center shadow w-fit h-full border border-gray-300 rounded cursor-pointer"
          :class="{
            'bg-blue-600 text-white': currentStepPath.selectedOption.includes(option),
            'bg-white': !currentStepPath.selectedOption.includes(option)
          }"
        >
          <input
            type="checkbox"
            :id="`equipment-${index}`"
            :value="option"
            @change="schmitzStore.selectMutlipleCheckboxes(option, schmitzStore.currentStepKey)"
            class="hidden"
          />
          {{ option.label }}
        </label>
      </div>

      <label class="block mt-4"
        ><span class="text-sm font-semibold">Sonstiges</span
        ><textarea class="form-input w-full mt-1" v-model="customEquipmentInput"></textarea>
      </label>
    </div>
  </div>
  <p
    v-if="schmitzStore.vehicleSelectionError"
    class="mt-4 text-xs text-red-500 text-right whitespace-pre-line"
  >
    {{ schmitzStore.vehicleSelectionError }}
  </p>
  <button
    class="mt-4 ml-auto flex items-center bg-blue-600 text-white font-semibold text-xl px-4 py-1 border rounded shadow-md hover:bg-blue-800 disabled:opacity-50"
    @click="nextSelection()"
  >
    Weiter
  </button>
</template>

<script setup>
import { useSchmitzStore } from '@/stores/schmitzStore'
import { computed, ref } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const customEquipmentInput = ref('')
const schmitzStore = useSchmitzStore()
function nextSelection() {
  if (schmitzStore.currentStepKey === 'customEquipment') {
    if (customEquipmentInput !== '') {
      schmitzStore.selectMutlipleCheckboxes(
        {
          value: customEquipmentInput,
          label: `custom-equipment`
        },
        schmitzStore.currentStepKey
      )
    }
  }
  schmitzStore.nextSelection()
}
const currentStepPath = computed(() => {
  return schmitzStore.accordion[schmitzStore.currentExpandedKey].steps[schmitzStore.currentStepKey]
})
</script>
