import { createApp, defineCustomElement, h } from 'vue'
import { createPinia } from 'pinia'
import schmitzWidget from './schmitzWidget.ce.vue'
import indexCss from './index.css?inline'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faChevronUp,
  faPencil,
  faCircleExclamation,
  faArrowLeft
} from '@fortawesome/free-solid-svg-icons'
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const app = createApp({})
library.add(faChevronUp, faPencil, faCircleCheck, faCircleExclamation, faArrowLeft)
app.use(createPinia())

app.component('FontAwesomeIcon', FontAwesomeIcon)

const SchmitzWidgetElement = defineCustomElement({
  setup() {},
  render() {
    return h(schmitzWidget)
  },

  mounted() {
    const styleElement = document.createElement('style')
    styleElement.textContent = indexCss.toString()
    this.$el.appendChild(styleElement)
  }
})

customElements.define('schmitz-tradein-widget', SchmitzWidgetElement)
