<script setup>
import { onMounted } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { useSchmitzStore } from './stores/schmitzStore.js'
import model from './components/model.vue'
import carRegistration from './components/carRegistration.vue'
import colors from './components/colors.vue'
import equipment from './components/equipment.vue'
import damage from './components/damage.vue'
import priceRequest from './components/priceRequest.vue'
import submit from './components/submit.vue'
import LoadingScreen from './utils/loadingScreen.vue'
import ErrorScreen from './utils/errorScreen.vue'
import RequestDone from './utils/requestDone.vue'
const schmitzStore = useSchmitzStore()

const components = {
  model,
  colors,
  equipment,
  carRegistration,
  damage,
  priceRequest,
  submit
}
onMounted(async () => {
  schmitzStore.defineStepKey()
})
</script>

<template>
  <div class="container">
    <RequestDone v-if="schmitzStore.responseDataCheck" />

    <div v-else-if="schmitzStore.error">
      <div class="bg-red-200 p-4 text-red-800 antialiased">
        <h1 class="font-bold text-4xl">Systemfehler</h1>
        <pre class="mt-4 pt-4 text-sm leading-none"
          >{{ schmitzStore.error.message }} {{ schmitzStore.error.detail }}</pre
        >
      </div>
    </div>

    <ErrorScreen v-else-if="schmitzStore.requestError || schmitzStore.inputError" />

    <LoadingScreen v-else-if="schmitzStore.loading" />
    <div v-else class="accordion">
      <transition-group name="accordion-transition">
        <div
          class="accordion"
          v-for="(accordionStep, index) in schmitzStore.accordion"
          :key="index"
          :class="{ active: schmitzStore.currentExpandedKey === index }"
        >
          <div class="accordion-header flex items-center px-2 py-2">
            <span class="w-6 mx-4 font-semibold text-4xl text-center">{{
              Object.keys(schmitzStore.accordion).indexOf(index) + 1
            }}</span>
            <div>
              <h3 class="text-xl text-gray-800">
                {{ accordionStep.title }}
              </h3>
              <div class="text-xs leading-tight font-light text-gray-600">
                {{
                  schmitzStore.displaySelected(accordionStep.content)
                    ? schmitzStore.displaySelected(accordionStep.content)
                    : ''
                }}
              </div>
            </div>
            <div class="ml-auto flex gap-8 text-gray-500">
              <FontAwesomeIcon
                icon="fa-solid fa-pencil"
                class="accordion-icon my-2"
                v-if="schmitzStore.currentExpandedKey === index"
              />

              <FontAwesomeIcon
                icon="fa-regular fa-circle-check"
                class="accordion-icon my-2"
                style="color: #36ff04"
                v-if="
                  Object.values(schmitzStore.accordion[index].steps).every((step) => step.selected)
                "
              />
              <svg
                class="accordion-icon my-2 mr-2"
                :class="{
                  'rotate-180  ': schmitzStore.currentExpandedKey === index
                }"
                @click="schmitzStore.toggleAccordion(index)"
              >
                <FontAwesomeIcon
                  icon="fa-solid fa-chevron-up"
                  v-if="
                    schmitzStore.currentExpandedKey === index ||
                    schmitzStore.previousAccordionKey === index ||
                    Object.values(schmitzStore.accordion[index].steps).some(
                      (step) => step.selected
                    ) ||
                    Object.values(schmitzStore.accordion[index].steps).some((step) => step.visited)
                  "
                />
              </svg>
            </div>
          </div>

          <div v-if="schmitzStore.currentExpandedKey === index">
            <div
              class="accordion-content px-4 px-4 py-4 bg-gray-200 shadow-inner"
              :class="{ expanded: schmitzStore.currentExpandedKey === index }"
            >
              <component :is="components[accordionStep.content]" />
            </div>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<style scoped>
@import './index.css';
</style>
